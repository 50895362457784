import React, {useContext, useState, useCallback, useEffect} from "react";
import {getStateAbbr} from "../../../../../utils/get_state_abbr";
import {imgUrl} from "../../../../../../services/constants";
import "./listing_list_view.scss"
import applyNowLogo from "../../../../../../../assets/images/broker_portal/apply_now_logo.png"
import {IListing} from "../../../../../../services/api/listing";
import {ListingSearchContext} from "../../../../../components/listing_search/listing_search_context";
import {Dialog, TableCell, TableRow, makeStyles} from "@material-ui/core";
// import { makeStyles } from '@material-ui/core/styles';
import {FloorPlanDialog} from "../../shared/floor_plan_dialog";
import {RBCheckbox} from "../../../../../components/rb_checkbox/rb_checkbox";
import {SelectedListingsContext} from "../../../selected_listings_context";
import {AgentUserContext} from "../../../agent_user_context";
import QueueIcon from '@material-ui/icons/Queue';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import moment from "moment";
import {BookmarksContext} from "../../../bookmarks_context";
import {getOwnerName, getOwnerLabel} from "../../../utils/utils";
import {ShareDialog} from "../../../../../components/share_dialog/ShareDialog";
import {ClaimListing} from "../../../../broker_portal/claim_listing/claim_listing";
import {Drawer} from "../../../../../components/drawer/drawer";
import {SweetAlertContext} from "../../../../../components/sweet_alert/sweet_alert";
import { useTranslation } from "react-i18next";
import ApplicationInviteModal from "../../../../broker_portal/applications/application_invite_modal";
import { agentClientsApi } from "../../../../../../services/rails_api/agent/clients";
import axios from "axios";
import NewClientModal from "../../../../broker_portal/applications/new_client_modal";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
           padding: '0 !important',
           margin: '0', // Optional: Adjust margin if needed
       },
     dialogTitle: {
         padding: '0 !important',
     },
     dialogContent: {
         minHeight: 300
     },
     dialogActions: {
       justifyContent: 'center',
       marginBottom: 15
     },
     closeButton: {
         position: 'absolute',
         right: theme.spacing(1),
         top: theme.spacing(1),
         color: theme.palette.grey[500],
     },
     modalHeaderContainer: {
         padding: '16px 24px',
     },
     hrContainer: {
         padding: '0 24px',
     },
     hrDivider: {
         margin: 0,
     }
}));

const ListingListView = React.memo(
    function ListingListViewComponent(props: IListingListView) {
        const [isExpanded, setIsExpanded] = useState(false);
        const [buildingListings, setBuildingListings] = useState([] as IListing []);
        const [shareDialogOpen, setShareDialogOpen] = useState(false);
        const dialogClassess = useStyles();

        const [openModal, setOpenModal] = useState<boolean>(false);
        const [newClientModal, setNewClientModal] = useState<boolean>(false);
        const [selectedRow, setSelectedRow] = useState(null);
        const clientContactInfo = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            status: "active",
            name: "",
            client_type: "renter",
            id: 0
        }
        const [applicants, setApplicants] = useState([clientContactInfo]);
        const [newClientIndex, setNewClientIndex] = useState(null);
        const [clients, setClients] = useState([]);
        const [selectedClient, setSelectedClient] = useState(null);


        const {isFullAgent, isMlsAgent, agentId, agentInfo, countryCode} = useContext(AgentUserContext);
        const {getGroupListingUnits, criteria} = useContext(ListingSearchContext);
        const { t } = useTranslation();
        const {
            setGroupedListings,
            handleMergeGroupListings,
            handleSelectedListing,
            isMlsAgentAndNonMlsListing,
            isGatewayAccessibleListing,
            isGatewayAgentAndNonMlsListing,
            isMlsAgentAndEmptyDisclaimer,
            isMLSListing,
            disableCopyPaste,
            rowPerPageRenderer
        } = useContext(SelectedListingsContext);

        const {toggleBookmarks} = useContext(BookmarksContext);
        const {triggerAlert} = useContext(SweetAlertContext);

        const {listing, isAddressDisplay, toggleUpgradeModal, showShowUpgradeModal} = props;

        useEffect(() => {
            setGroupedListings([])
            if (listing.apt_count > 1) {
                getFloorPlans(true)
            }

            // perform a cleanup
            return () => {
                setGroupedListings([])
                setBuildingListings([])
            }
        }, [rowPerPageRenderer, criteria?.page_number])

        const handleDialogClose = () => {
            setShareDialogOpen(false);
        };

        const [drawerOpen, setDrawerOpen] = useState(false);
        const [listingToClaim, setListingToClaim] = useState(null);

        const getFloorPlans = async (isNotOpen?: boolean) => {
            const listings = await getGroupListingUnits(listing, agentInfo.super_region);
            setBuildingListings(listings);
            if (isNotOpen) handleMergeGroupListings(listings, props.pageNumber)
        };

        const hasGroupBookmarkedListing = () => {
            return props.bookmarks.findIndex(bookmark => bookmark.address == listing.address) > -1
        };

        const openDialog = () => {
            setIsExpanded(true);
            getFloorPlans();
        };

        const handleDialogOpen = () => {
            if (!drawerOpen) {
                if (isFullAgent() || isGatewayAccessibleListing(listing)) {
                    if (listing.apt_count > 1) {
                        openDialog();
                    } else {
                        const url = `/broker_portal/listing_details/${listing.apartment_id}`;
                        window.open(url, '_blank');
                    }
                }
            }
        };

        const showOrHideOwnerName = (listing) => {
            if (isFullAgent() || isGatewayAccessibleListing(listing)) {
                return getOwnerName(listing)
            }
            else {
                return ""
                // TODO - Update once Harald updates the requirements
            }
        }

        const getSquareFootage = (listing) => {
            let str: string;
            if(listing.apt_count > 1) {
                str = (listing.min_square_footage > 0 || listing.max_square_footage > 0) ?
                    `${listing.min_square_footage} - ${listing.max_square_footage}` : "--";
            }
            else {
                str = listing.square_footage || "--";
            }
            return str;

        };

        const handleRowCheck = () => {
            if (listing.apt_count > 1) {
                openDialog();
            } else {
                handleSelectedListing(listing.apartment_id, !props.checked, listing.apartment_id, props.listings, criteria?.page_number);
            }
        };

        const tableCellClickHandler =  useCallback(() => {
                if (isFullAgent()) {
                    return;
                }
                if (isAddressDisplay) {
                    if (isMLSListing(listing)) {
                        if (listing.apt_count >  1) {
                            handleRowCheck()
                        }
                        return
                    } else {
                        toggleUpgradeModal(listing.apartment_id)
                        // window.location.href = `/broker_portal/listing_details/${listing.apartment_id}?access=nonpro`
                        // window.open(`/broker_portal/listing_details/${listing.apartment_id}?access=nonpro`)

                    }
                }
        }, [listing])

        const showOrHideAddress = (listing) => {
            if (isGatewayAgentAndNonMlsListing(listing) || (isMlsAgentAndEmptyDisclaimer(listing))) {
                return <TableCell
                        align="left"
                        >
                        <div
                        onPaste={disableCopyPaste}
                        onCopy={disableCopyPaste}
                        ref={listingAddress => {
                            listingAddress  && listingAddress.addEventListener('selectstart', (e) => e.preventDefault())
                        }}
                        className="listing-full-address"
                        onClick={tableCellClickHandler}
                        >

                            <span >
                                {
                                isAddressDisplay ?
                                `${listing.address}, ` :
                                <>
                                <a href="/agent/subscriptions_and_payment">Upgrade</a> to see address,{" "}
                                </>
                                }
                            </span>

                            <span>{listing.city}, {getStateAbbr(listing.state, countryCode)} {listing.zip}</span>
                        </div>
                </TableCell>
            }
            else {
                return <TableCell align="left">
                    <div
                    onPaste={e => !isFullAgent() && disableCopyPaste(e)}
                    onCopy={e => !isFullAgent() &&  disableCopyPaste(e)}
                    ref={listingAddress => {
                            !isFullAgent() &&  listingAddress  && listingAddress.addEventListener('selectstart', (e) => e.preventDefault())
                    }}
                    className={`${!isFullAgent() && 'listing-full-address'}`}
                    >
                        <span>{listing.address}, </span>
                        <span>{!isMLSListing(listing) &&
                        listing.apt_count === 1 && listing.apt_number !== '' ? `Unit ${listing.apt_number}, `: null} {listing.city},  {getStateAbbr(listing.state, countryCode)}{" "} {listing.zip}</span>
                    </div>
                </TableCell>
            }
        };


        const getDisclaimer = (listing) => {
            let disclaimer = listing.disclaimer
            if (listing.listing_source_type === 'RBG'){
                return (
                    <div className="disclaimer-row">
                        <span className="row-rbg">RBG</span>
                    </div>
                );
            }
            else if(disclaimer?.rb_verified === true) {
                return (
                <div className="disclaimer-row" onClick={tableCellClickHandler}>
                    <div className="review-checkbox" title="RENTAL BEAST REVIEWED">
                    <img
                        src={`${imgUrl}/icons/rb-verified.png`}
                        className="rb-check"
                        alt="rental beast reviewed"
                    />
                    {/*<span className="rb-verified ">{disclaimer.tile_disclaimer}</span>*/}
                    </div>
                </div>
                );
            }
            else if(disclaimer?.rb_verified === false) {
                return (
                    <div className="disclaimer-row row-mls">
                       <span>{t('MLS')}</span>
                        <input type="checkbox" checked={true} disabled={true}/>
                    </div>
                );
            }
        };

        const singleOrMultiUnitCheckBox = (listing) => {
            if (listing.apt_count > 1) {
                if (props.checked === true) {
                    return <LibraryAddCheckIcon className="check-icon"/>
                }
                else {
                    return <div onClick={handleRowCheck}> <QueueIcon className="queue-icon"/> </div>
                }
            } else {
                if (isMlsAgentAndNonMlsListing(listing) || isMlsAgentAndEmptyDisclaimer(listing)) {
                    return null;
                } else {
                    return (
                        <RBCheckbox name={listing.apartment_id.toString()} checked={props.checked} handleChange={() => handleRowCheck()}/>
                    );
                }
            }
        };

        const handleShareClick = (e) => {
            if (listing.apt_count > 1) {
                return;
            } else {
                e.stopPropagation();
                setShareDialogOpen(true);
            }
        }

        const getClaimImage = () => {
            return `${imgUrl}/icons/claim_icon_blue.png`;
        };

        const handleActionClick = (action, e, listing) => {
            e.stopPropagation();
            if (action == 'claim') {
                handleClaim(listing);
            }
        };

        const showClaimErrorAlert = (content) => {
            triggerAlert("error", content);
        };

        const handleClaim = (listing) => {
            setListingToClaim(listing);
            if (listing?.email === "" && listing?.direct_phone === "" ) {
                showClaimErrorAlert("Sorry, the property contact for the selected listing has not provided any contact information. You need to contact this owner directly and request that the owner provides Rental Beast with a contact email address.");
            } else {
                setDrawerOpen(true);
            }
        };

        const handleCloseClaimDrawer = (e) => {
            e.stopPropagation();
            setDrawerOpen(false);
        };

        const handleCloseClaimModal = (e) => {
            e.stopPropagation();
            setDrawerOpen(false);
        };

        const showOrHideClaimLinkIcon = (listing) => {
            const markup = (
                <>
                    <span className="icon-div-cont">
                        <img title="Claim" src={getClaimImage()} onClick={(e) => handleActionClick('claim', e, listing)} className="claim-icon" alt="Claim Listing" height="16"/>
                    </span>

                    <Drawer open={drawerOpen} handleClose={(e) => handleCloseClaimModal(e)} width={80}>
                        <ClaimListing listing={listingToClaim} agentInfo={listing.agent_info} cancelHandler={(e) => handleCloseClaimDrawer(e)}/>
                    </Drawer>
                </>
            );
            // 1. To show claim listing button on listings not owned by an agent (type_contact 3 (agent)), and not belonging to a listing agent (listing_agent_id is 0 or empty)
            // 2. MLS Only users can claim Rental Beast Reviewed and Non-RB Reviewed listings
            // 3. MLS Only users cannot claim MLS Listings.
            // 4. Full Agent users can claim Rental Beast Reviewed and Non-RB Reviewed listings
            if (
                ((listing.type_contact !== 3 && !listing.listing_agent_id ) ||
                (isGatewayAccessibleListing(listing) && listing.is_agent_listing) ||
                (isMlsAgentAndNonMlsListing(listing) && listing.is_agent_listing) ||
                (isFullAgent() && listing.disclaimer?.rb_verified === true && listing.is_listing_free || listing.is_agent_listing) ||
                (isFullAgent() && listing.disclaimer?.rb_verified === false && listing.is_listing_free || listing.is_agent_listing)) &&
                listing.listing_source_type !== 'MLS'
            ) {
                return markup;
            } else {
                return null;
            }
        };

        const showOrHideExternalLinkIcon = (listing) => {
            if (listing.apt_count > 1) {
                return <i className="fas fa-search-plus"/>
            }
            return isMlsAgentAndNonMlsListing(listing) && listing?.disclaimer?.rb_verified === true ? null : <i className="fas fa-external-link-alt"/>
        };

        const openInviteApplicationModal = (row) => {
            setOpenModal(true)
            setSelectedRow(row)
        }

        const showOrHideApplyNowIcon = (listing) => {
            return (listing?.use_apply_now && listing?.apt_count <= 1 && (listing?.status === 'Active' || listing?.status === 'Application Pending')) && (
                <img
                    width="24"
                    src={applyNowLogo}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        openInviteApplicationModal(listing)
                    }}
                />
            )
        };

        const openNewClientModal = (index) => {
            setNewClientIndex(index)
            // setOpenModal(false)
            setNewClientModal(true)
        }

        const closeModal = () => {
            setOpenModal(false)
            setApplicants([clientContactInfo])
        }

        const closeNewClientModal = () => {
            setNewClientModal(false)
            setOpenModal(true)
            setNewClientIndex(null)
        }

        const getClients = async () => {
            if (!agentId) return
            try {
                const response = await agentClientsApi.getList({
                    agentId
                })
                setClients(response)
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            getClients()
        }, [agentId, agentInfo])

        const selectClient = (index = null, value) => {
            const [firstName, lastName] =  value.value ? value.value.name.split(' ') : value.name.split(' ');
            const clientDetails = {
                first_name: firstName,
                last_name:  lastName,
                email: value.value.main_email,
                phone: value.value.main_phone, // Make sure this is correct
                name: value.value.name || `${value.value.first_name} ${value.value.last_name}`,
                status: "Active",
                client_type: "renter",
                id: value.value.id
            };
            const currentIndex = newClientIndex ? newClientIndex : index
            setApplicants(applicants.map((applicant, i) => i === currentIndex ? { ...applicant, ...clientDetails } : applicant));
        };

        const addNewAppicant = () => {
            setApplicants([...applicants, clientContactInfo])
        }

        const removeApplicant = () => {
            if (applicants.length > 1) {
                setApplicants(applicants.slice(0, -1))
            } else {
                setApplicants([...applicants.slice(0, -1), clientContactInfo])
            }
        };

        const inviteApplicants = async () => {
            const [firstName, lastName] = agentInfo.full_name.split(' ');

            const applicantLists = applicants.map(applicant => ({
                firstName: applicant.first_name,
                lastName: applicant.last_name,
                email: applicant.email,
                confirmEmail: applicant.email,
                phoneNumber: applicant.phone
            }));

            const landlord = {
                email: agentInfo.email,
                confirmEmail: agentInfo.email,
                first_name: firstName,
                last_name: lastName,
                phone: agentInfo.phone,
            };
            const selectedListing = {
                area: selectedRow.square_footage,
                baths: String(selectedRow.bathrooms),
                beds:  String(selectedRow.beds_count),
                city: selectedRow.city,
                listing_id: selectedRow.apartment_id,
                rent: selectedRow.rent,
                state: getStateAbbr(selectedRow.state, countryCode),
                street_address: selectedRow.address,
                unit_no: selectedRow.apt_number,
                zip: selectedRow.zip,
            }

            const payload = {
                applicants: applicantLists,
                landlord,
                listing: selectedListing,
                partney_key:  agentInfo.partner_key,
                role: "broker"
            }
            const response = await axios.post(`${window.location.origin}/free_tenant_screening`, payload)
            // console.log('response', response);


            if (response) {
                closeModal()
            }

        }

        const showOrHideShareLinkIcon = (listing) => {
            if (isFullAgent() && listing.is_listing_free || listing.is_agent_listing ) {
                return <i className="fas fa-share" onClick={handleShareClick}/>
            } else if (isMlsAgent() && listing.is_agent_listing) {
                return <i className="fas fa-share" onClick={handleShareClick}/>
            }
        };

        const getRentChange = () => {
            const rentChanges = listing.rent_changes
            const rentChangeVaue = ((rentChanges || [])[0] || {})
            const rentChangeAbsolute = rentChangeVaue?.absolute > 0
            let rentChange: any = null
            const arrowStyle: any = {
                fontWeight: 'bold',
                fontSize: '140%',
                margin: "-7px 0px 0px 5px",
                position: 'absolute'
            }

            if (rentChangeVaue?.absolute) {
                if (rentChangeAbsolute) rentChange = <span style={{ ...arrowStyle, color: '#81C784' }}>&uarr;</span>
                else rentChange = <span style={{ ...arrowStyle, color: 'red' }}>&darr;</span>
            }

            return listing.apt_count > 1 ? null : rentChange
        }

        return <React.Fragment>
                    <TableRow key={listing.apartment_id} className="detail--row container-fluid zebra-stripes" onClick={handleDialogOpen}>
                    {/*<TableCell align="left">{listing.building_name}</TableCell>*/}
                        <TableCell align="left" className="bookmark-flag-cell">{hasGroupBookmarkedListing() ? <i onClick={(e) => toggleBookmarks(e, [listing.apartment_id])} className="fas fa-bookmark floorplan__title--bookmark"/> : null}</TableCell>
                        <TableCell align="left">
                            {singleOrMultiUnitCheckBox(listing)}
                        </TableCell>
                        <TableCell align="left">
                            {
                                getDisclaimer(listing)
                            }
                        </TableCell>
                        { showOrHideAddress(listing) }
                        <TableCell align="left">{listing.min_beds === listing.max_beds ? listing.min_beds : `${listing.min_beds} - ${listing.max_beds}`}</TableCell>
                        <TableCell align="left">{listing.min_baths === listing.max_baths ? listing.min_baths : `${listing.min_baths} - ${listing.max_baths}`}</TableCell>
                        <TableCell align="left">{listing.min_rent === listing.max_rent ? `$${listing.min_rent}` : `$${listing.min_rent} - $${listing.max_rent}`} {getRentChange()}</TableCell>
                        <TableCell align="left">{listing.apt_count}</TableCell>
                        {/* tableCellClickHandler */}
                        <TableCell align="left">{moment(listing.date_available).format('MM/DD/YY')}</TableCell>
                        {/* --- DOM Value --- */}
                        <TableCell align="left">{listing.dom?.length ? listing.dom[0]?.calculated_days_on_market : 0}</TableCell>
                        {/* --- END --- */}
                        <TableCell align="left" onClick={tableCellClickHandler}>{listing.apt_count > 1 ? <a href="#" className="link-icon">Details</a> : listing.status}</TableCell>
                        {/*<TableCell align="left">{listing.owner_pay}</TableCell>*/}
                        {/*<TableCell align="left">{getLongTermCommission(listing)}</TableCell>*/}
                        <TableCell align="left">{getSquareFootage(listing)}</TableCell>
                        <TableCell>{getOwnerLabel(listing)}</TableCell>
                        <TableCell>{showOrHideOwnerName(listing)}</TableCell>
                        <TableCell align="left" style={{fontSize: "1rem"}}>
                        {showOrHideExternalLinkIcon(listing)}
                        </TableCell>
                        <TableCell align="left" style={{fontSize: "1rem"}}>{showOrHideApplyNowIcon(listing)}</TableCell>
                        <TableCell align="left" style={{fontSize: "1rem"}}>
                        {listing.listing_source_type !== 'RBG' && showOrHideClaimLinkIcon(listing)}
                        </TableCell>
                        <TableCell align="left" style={{fontSize: "1rem"}}>
                        {showOrHideShareLinkIcon(listing)}
                        </TableCell>
                    </TableRow>
                    <FloorPlanDialog
                        pageNumber={props.pageNumber}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        buildingListings={buildingListings}
                        listing={listing}
                        isMlsAgent={isMlsAgent}
                        isAddressDisplay={isAddressDisplay}
                        isFullAgent={isFullAgent}
                        toggleUpgradeModal={toggleUpgradeModal}
                        showShowUpgradeModal={showShowUpgradeModal}
                    />
                    <ShareDialog
                        shareDialogOpen={shareDialogOpen}
                        handleDialogClose={handleDialogClose}
                        listingId={listing.apartment_id}
                    />
                    <Dialog
                        open={openModal}
                        onClose={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenModal(false);
                            setNewClientModal(false)
                            setApplicants([clientContactInfo])
                        }}
                        maxWidth="xs"
                        fullWidth
                        PaperProps={{
                            style: {
                                padding: 0,
                                margin: 0,
                                position: 'relative'
                            },
                        }}
                    >
                        {
                            newClientModal ? (
                                <NewClientModal
                                    dialogClassess={dialogClassess}
                                    closeModal={closeNewClientModal}
                                    clientContactInfo={clientContactInfo}
                                    selectClient={selectClient}
                                    getClients={getClients}
                                    newClientIndex={newClientIndex}
                                />
                            ) : (
                                <ApplicationInviteModal
                                    selectedRow={selectedRow}
                                    closeModal={closeModal}
                                    dialogClassess={dialogClassess}
                                    clients={clients}
                                    selectClient={selectClient}
                                    selectedClient={selectedClient}
                                    openNewClientModal={openNewClientModal}
                                    applicants={applicants}
                                    addNewAppicant={addNewAppicant}
                                    removeApplicant={removeApplicant}
                                    inviteApplicants={inviteApplicants}
                                />
                            )
                        }
                    </Dialog>
                </React.Fragment>
    }
)

export interface IListingListView {
    listing: IListing,
    listings?: IListing[],
    pageNumber?: number;
    onMergeGroupListings?: (listings: IListing[], pageNumber: number) => any,
    handleBuildingLisings?: (buildingListings: IListing[]) => any,
    handleBookmark: () => void;
    bookmarks: { apartment_id: number, address: string } [];
    checked: boolean;
    isAddressDisplay:boolean
    toggleUpgradeModal: (listingId?: any) => void
    showShowUpgradeModal: boolean
}

export { ListingListView }
