import React, { useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { ActiveRentPins } from "./map_view_pins/active_rent_pins";
import { SelectedListingsContext } from "../../selected_listings_context";
import ListingDialog from "./map_dialog";
import { SHAPE } from "./draw_utils/draw_constants";
import { POI_TYPES, POI_ICONS, POI_BACKGROUND_COLORS } from "./layer_utils/layer_constants";
import uuidv4 from "uuid/v4";
import _ from "lodash"
import { ShapeOptions } from "./shape_options";
import { useMapShape } from "./map_view_hook/useMapShape";
import { googleMapApiKey } from "../../../../../services/constants";
import { ListingSearchContext } from "../../../../components/listing_search/listing_search_context";
import { AgentUserContext } from "../../agent_user_context";
import { Dialog } from "@material-ui/core";
import { schoolDistrictApi, subdivisionApi, schoolsApi, ISchoolSelectors } from "../../../../../services/api/school_auto_complete";
import { IListing } from '../../../../../services/api/listing';
import { SelectedRentPins } from './map_view_pins/selected_rent_pins';
import NewClientModal from '../../../broker_portal/applications/new_client_modal'
import ApplicationInviteModal from '../../../broker_portal/applications/application_invite_modal'
import { agentClientsApi } from '../../../../../services/rails_api/agent/clients'
import axios from "axios";
import {makeStyles} from "@material-ui/core";
import { getStateAbbr } from '../../../../utils/get_state_abbr';

function updateOptions(loadOptions, { mapsApi, selectedSchoolLayers }) {
    loadOptions(mapsApi, selectedSchoolLayers)
}

const debounced = _.debounce(updateOptions, 300)

const ListingMapResultPins = ({
    lat,
    lng,
    status,
    listing,
    rent,
    aptCount,
    hoveredListingId,
    listingId,
    hoverListingAction,
    isSingleCheck,
    bookmarks,
    handleBookmark,
    activeTooltipId,
    onActiveTooltipId,
    activeModalId,
    onActiveModalId,
    isAddressDisplay,
    totalListingCount,
    onPreventZoomIn,
    onOpen,
    listingsMapArea,
    onCenterFocus,
    currentPage,
    onSetCurrentPage,
    setMultiSelect,
    setSingleSelect,
    onIndividualListing,
    domAndRentChanges: { dom, rentChanges },
    openInviteApplicationModal
}) => {
    const getDOM = () => ((dom || [])[0] || {})?.calculated_days_on_market || 'N/A'

    const isListingDisabled =
        status === "Cancelled" ||
        status === "Withdrawn" ||
        status === "Expired";

    const isMlsListing = listing.listing_source_type === 'MLS'
    const isRBGListing = listing.listing_source_type === 'RBG'

    const { isMlsAgent } = useContext(AgentUserContext)
    const { selectedListings } = useContext(SelectedListingsContext)
    const { setShapeOptionLatLng } = useContext(ListingSearchContext)

    const removeExcludeDeleteModal = () => {
        setShapeOptionLatLng({
            lat: null,
            lng: null,
        })
    }


    const pinClickHandler = (isSelected : boolean) => {
        removeExcludeDeleteModal()
        setMultiSelect(false)
        setSingleSelect(true)
        if (!isSingleCheck) {
            onCenterFocus({ lat, lng })
            onActiveTooltipId(0)
            onActiveModalId(listingId)
            onSetCurrentPage(1)
            return
        }

        if (isSingleCheck && (isMlsAgent() && !isMlsListing)) {
            onOpen()
            return
        }

        onIndividualListing(listing)
    };

    const isListingBeingSelected: boolean = React.useMemo(() => {
        return (
            selectedListings.length > 0 &&
            selectedListings.some(
                (selectedListing) => selectedListing.listingId === listingId
            )
        );
    }, [selectedListings]);

    const isBubbleVisible: boolean = React.useMemo(() => {
        return (
            listingsMapArea.length > 0 &&
            listingsMapArea.some(
                (listing : any) => listing.apartment_id === listingId
            )
        )
    }, [listingsMapArea]);

    const mlsListingPin = (status) => {
        return isMlsListing ? (
            <div
                style={{ cursor: "pointer" }}
                className={`mls-map-pin${hoveredListingId === listingId ? '-hover' : `_${status}`} ${status}-pin ${
                    hoveredListingId === listingId
                        ? "pin-hover"
                        : ""
                }`}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    pinClickHandler(
                        !selectedListings.includes(listingId)
                    );
                }}
                onTouchStart={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    pinClickHandler(
                        !selectedListings.includes(listingId)
                    );
                }}
                onMouseEnter={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    hoverListingAction(listingId);
                    onActiveTooltipId(listingId);
                }}
            />
        ) : (
            <div
                style={{ cursor: "pointer" }}
                className={`map-pin ${status}-pin ${
                    hoveredListingId === listingId
                        ? "pin-hover"
                        : ""
                }`}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    pinClickHandler(
                        !selectedListings.includes(listingId)
                    );
                }}
                onTouchStart={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    pinClickHandler(
                        !selectedListings.includes(listingId)
                    );
                }}
                onMouseEnter={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    hoverListingAction(listingId);
                    onActiveTooltipId(listingId);
                }}
            />
        )
    }

    return (
        <>
            <ListingDialog
                bookmarks={bookmarks}
                handleBookmark={handleBookmark}
                activeTooltipId={activeTooltipId}
                onActiveTooltipId={onActiveTooltipId}
                hoveredListingId={hoveredListingId}
                onHoveredListingId={hoverListingAction}
                activeModalId={activeModalId}
                onActiveModalId={onActiveModalId}
                isAddressDisplay={isAddressDisplay}
                totalListingCount={totalListingCount}
                onPreventZoomIn={onPreventZoomIn}
                isMlsListing={isMlsListing}
                isRBGListing={isRBGListing}
                coords={{ lat, lng }}
                onCenterFocus={onCenterFocus}
                currentPage={currentPage}
                onSetCurrentPage={onSetCurrentPage}
                {...listing}
                dom={getDOM()}
                rentChanges={rentChanges}
                openInviteApplicationModal={openInviteApplicationModal}
            />
            {status === "Active" && (
                <>
                    {isListingBeingSelected ? (
                        <SelectedRentPins
                            listingId={listingId}
                            hoverListingAction={hoverListingAction}
                            isSingleCheck={isSingleCheck}
                            onActiveTooltipId={onActiveTooltipId}
                            isMlsListing={isMlsListing}
                            isMlsAgent={isMlsAgent}
                            onOpen={onOpen}
                            isBubbleVisible={isBubbleVisible}
                            removeExcludeDeleteModal={removeExcludeDeleteModal}
                            coords={{ lat, lng }}
                            onCenterFocus={onCenterFocus}
                            onActiveModalId={onActiveModalId}
                            onSetCurrentPage={onSetCurrentPage}
                            onIndividualListing={onIndividualListing}
                            listing={listing}
                        />
                    ) : (
                        <ActiveRentPins
                            rent={rent}
                            aptCount={aptCount}
                            hoveredListingId={hoveredListingId}
                            listingId={listingId}
                            hoverListingAction={hoverListingAction}
                            isSingleCheck={isSingleCheck}
                            activeTooltipId={activeTooltipId}
                            onActiveTooltipId={onActiveTooltipId}
                            isMlsListing={isMlsListing}
                            isMlsAgent={isMlsAgent}
                            onOpen={onOpen}
                            isBubbleVisible={isBubbleVisible}
                            removeExcludeDeleteModal={removeExcludeDeleteModal}
                            coords={{ lat, lng }}
                            onCenterFocus={onCenterFocus}
                            onActiveModalId={onActiveModalId}
                            onSetCurrentPage={onSetCurrentPage}
                            onIndividualListing={onIndividualListing}
                            listing={listing}
                            rentChanges={rentChanges}
                        />
                    )}
                </>
            )}
            {status === "Rented" && (
                <>
                    {isListingBeingSelected ? (
                        <SelectedRentPins
                            listingId={listingId}
                            hoverListingAction={hoverListingAction}
                            isSingleCheck={isSingleCheck}
                            onActiveTooltipId={onActiveTooltipId}
                            isMlsListing={isMlsListing}
                            isMlsAgent={isMlsAgent}
                            onOpen={onOpen}
                            isBubbleVisible={isBubbleVisible}
                            removeExcludeDeleteModal={removeExcludeDeleteModal}
                            coords={{ lat, lng }}
                            onCenterFocus={onCenterFocus}
                            onActiveModalId={onActiveModalId}
                            onSetCurrentPage={onSetCurrentPage}
                            onIndividualListing={onIndividualListing}
                            listing={listing}
                        />
                    ) : mlsListingPin('rented')}
                </>
            )}
            {status === "Application Pending" && (
                <>
                    {isListingBeingSelected ? (
                        <SelectedRentPins
                            listingId={listingId}
                            hoverListingAction={hoverListingAction}
                            isSingleCheck={isSingleCheck}
                            onActiveTooltipId={onActiveTooltipId}
                            isMlsListing={isMlsListing}
                            isMlsAgent={isMlsAgent}
                            onOpen={onOpen}
                            isBubbleVisible={isBubbleVisible}
                            removeExcludeDeleteModal={removeExcludeDeleteModal}
                            coords={{ lat, lng }}
                            onCenterFocus={onCenterFocus}
                            onActiveModalId={onActiveModalId}
                            onSetCurrentPage={onSetCurrentPage}
                            onIndividualListing={onIndividualListing}
                            listing={listing}
                        />
                    ) : mlsListingPin('pending')}
                </>
            )}
            {isListingDisabled && (
                <>
                    {isListingBeingSelected ? (
                        <SelectedRentPins
                            listingId={listingId}
                            hoverListingAction={hoverListingAction}
                            isSingleCheck={isSingleCheck}
                            onActiveTooltipId={onActiveTooltipId}
                            isMlsListing={isMlsListing}
                            isMlsAgent={isMlsAgent}
                            onOpen={onOpen}
                            isBubbleVisible={isBubbleVisible}
                            removeExcludeDeleteModal={removeExcludeDeleteModal}
                            coords={{ lat, lng }}
                            onCenterFocus={onCenterFocus}
                            onActiveModalId={onActiveModalId}
                            onSetCurrentPage={onSetCurrentPage}
                            onIndividualListing={onIndividualListing}
                            listing={listing}
                        />
                    ) : mlsListingPin('cancel')}
                </>
            )}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
           padding: '0 !important',
           margin: '0', // Optional: Adjust margin if needed
       },
     dialogTitle: {
         padding: '0 !important',
     },
     dialogContent: {
         minHeight: 300
     },
     dialogActions: {
       justifyContent: 'center',
       marginBottom: 15
     },
     closeButton: {
         position: 'absolute',
         right: theme.spacing(1),
         top: theme.spacing(1),
         color: theme.palette.grey[500],
     },
     modalHeaderContainer: {
         padding: '16px 24px',
     },
     hrContainer: {
         padding: '0 24px',
     },
     hrDivider: {
         margin: 0,
     }
  }));


interface IMapSection {
    listings: any[];
    hoveredListingId: number;
    hoverListingAction: (number) => void;
    isSingleCheck: boolean;
    mapSetting: string;
    handleBookmark: () => void;
    bookmarks: { apartment_id: number; address: string }[];
    activeTooltipId: number;
    onActiveTooltipId: (val: number) => void | null;
    selectedShapeController: string;
    activeModalId: number;
    onActiveModalId: (val: number) => void | null;
    isAddressDisplay: boolean;
    totalListingCount: number;
    selectedLayers?: string[];
    setSelectedShapeController:  React.Dispatch<React.SetStateAction<string | null>>
    listingsMapArea: any[];
    selectedSchoolLayers?: ISchoolSelectors[];
    // --- new layer: Subdivision ---
    subdivisionLayer: boolean;
    // --- END ---
    schoolDistrictLayer: boolean;
    setMultiSelectDrawManager: React.Dispatch<React.SetStateAction<any>>
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    multiSelect: boolean
    setMultiSelect: React.Dispatch<React.SetStateAction<boolean>>
    singleSelect: boolean
    setSingleSelect: React.Dispatch<React.SetStateAction<boolean>>
    currentZoomLevel: number;
    setCurrentZoomLevel: React.Dispatch<React.SetStateAction<number>>
}

export const MapSection: React.FC<IMapSection> = React.memo(({
    listings,
    hoveredListingId,
    hoverListingAction,
    isSingleCheck,
    mapSetting,
    bookmarks,
    handleBookmark,
    activeTooltipId,
    onActiveTooltipId,
    selectedShapeController,
    activeModalId,
    onActiveModalId,
    isAddressDisplay,
    totalListingCount,
    selectedLayers,
    setSelectedShapeController,
    listingsMapArea,
    // --- new layer: Subdivision ---
    subdivisionLayer,
    // --- END ---
    schoolDistrictLayer,
    selectedSchoolLayers,
    setMultiSelectDrawManager,
    isOpen,
    setIsOpen,
    multiSelect,
    setMultiSelect,
    singleSelect,
    setSingleSelect,
    currentZoomLevel,
    setCurrentZoomLevel
}) => {

    const defaultProps = {
        center: {
            lat: listings.length > 0 ? listings[0].latitude : null,
            lng: listings.length > 0 ? listings[0].longitude : null,
        },
        zoom: currentZoomLevel,
    };

    const { selectedListings, handleAllSelectedListings, isUnselectActivated } = useContext(
        SelectedListingsContext
    );
    const [preventZoomIn, setPreventZoomIn] = useState<boolean>(false)
    const [mapsApi, setMapsApi] = useState<any>()
    const [centerFocus, setCenterFocus] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const { loadingMapFilterSearch, tabChosen} = useContext(ListingSearchContext)
    let [markers, setMarkers] = useState<any[]>([])
    let [schoolDistrictBoundaries, setSchoolDistrictBoundaries] = useState<any[]>([])
    let [subdivisionBoundaries, setSubdivisionBoundaries] = useState<any[]>([])
    let [schools, setSchools] = useState<any[]>([])
    let [individualListingCollection, setIndividualListingCollection] = useState<any[]>([]);
    const { countryCode } = useContext(AgentUserContext)


    const {
        drawShapePayload,
        shapeOptionLatLng,
        handleDrawShape,
        drawManagerHandler,
        setMapDrawManager,
        mapDrawManger,
        shapeMouseOver,
        shapeMouseOut,
        hoveredShapeId,
        handleExcludeListing,
        handleDeleteListing,
        setDrawShapes,
        drawShapes,
        handleResizeShape,
        polygonCenter,
        isClearedShape,
        resizeShapeManager,
        retrieveDrawings,
        setRetrieveDrawings
    } = useMapShape();


    const handleDrawComplete = (overlay, shape, drawingManager) => {
        handleDrawShape(overlay, shape, uuidv4());
        drawingManager.setDrawingMode(null);
        setSelectedShapeController(null)
        setDrawShapes((prevState) => [
            ...prevState,
            overlay
        ])
    }

    React.useEffect(() => {
        if (drawShapePayload.geometry.length === 0) {
            setRetrieveDrawings([])
            setDrawShapes([])
        }
    }, [drawShapePayload.geometry])

    React.useEffect(() => {
        if (selectedShapeController !== null) {
            drawManagerHandler(selectedShapeController);
        }
    }, [mapDrawManger, selectedShapeController]);

    React.useEffect(() => {
        if (drawShapes.length > 1) {
            drawShapes[drawShapes.length -1].setOptions({
                zIndex: drawShapes[drawShapes.length -1].zIndex + drawShapes.length
            })
        }
    }, [drawShapes])

    React.useEffect(() => {
        if (isClearedShape) {
            retrieveDrawings.forEach(shape => shape.setMap(null))
        }
    }, [isClearedShape])

    React.useEffect(() => {
        if (mapsApi) {
            const { maps} = mapsApi

            if (drawShapes.length > 0 ) {
                 resizeShapeManager(drawShapes, maps, drawShapePayload)
            }
            if (retrieveDrawings.length > 0) {
                resizeShapeManager(retrieveDrawings, maps, drawShapePayload)
            }

            let shapes = []; // array to store all the drawn shapes

            drawShapes.forEach((shapeDrawn) => {
            // add each new shape to the array
            shapes.push(shapeDrawn);

            // set the shape's zIndex based on its index in the array
            shapeDrawn.setOptions({
                zIndex: shapes.length,
            });

            maps.event.addListener(shapeDrawn, "click", (event) => {
                // move the clicked shape to the top of the stack
                shapeDrawn.setOptions({
                zIndex: shapes.length + 1,
                });

                // set the zIndex of all other shapes to their index in the array
                shapes.forEach((shape, index) => {
                if (shape !== shapeDrawn) {
                    shape.setOptions({
                    zIndex: index + 1,
                    });
                }
                });

                if (shapeDrawn instanceof maps.Circle) {
                shapeMouseOver(
                    { lat: shapeDrawn.center.lat(), lng: shapeDrawn.center.lng() },
                    shapeDrawn,
                    shapeOptionLatLng
                );
                } else if (shapeDrawn instanceof maps.Rectangle) {
                shapeMouseOver(
                    {
                    lat: shapeDrawn.getBounds().getCenter().lat(),
                    lng: shapeDrawn.getBounds().getCenter().lng(),
                    },
                    shapeDrawn,
                    shapeOptionLatLng
                );
                } else if (shapeDrawn instanceof maps.Polygon) {
                shapeMouseOver(
                    {
                    lat: polygonCenter(shapeDrawn).centerX,
                    lng: polygonCenter(shapeDrawn).centerY,
                    },
                    shapeDrawn,
                    shapeOptionLatLng
                );
                }
            });

            maps.event.addListener(shapeDrawn, "mouseout", (event) => {
                // set the zIndex of all shapes to their index in the array
                shapes.forEach((shape, index) => {
                shape.setOptions({
                    zIndex: index + 1,
                });
                });
            });
            });
        }
    }, [mapsApi, drawShapes, drawShapePayload, retrieveDrawings])


    const initGoogleMapApi = (map, maps) => {
        const SHAPE_OPTIONS = {
            fillColor: "#ffffff",
            fillOpacity: 0.5,
            strokeWeight: 5,
            clickable: true,
            editable: true,
            zIndex: 999,
            draggable: false,
        };

        const DRAW_CONFIG = {
            drawingControl: false,
            draggable: false,
            circleOptions: SHAPE_OPTIONS,
            rectangleOptions: SHAPE_OPTIONS,
            polygonOptions: SHAPE_OPTIONS,
        };
        const drawingManager = new maps.drawing.DrawingManager(DRAW_CONFIG);
            setMapDrawManager(drawingManager);
            drawingManager.setMap(map);
            maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
                const { type, overlay } = event;
                drawingManager.setDrawingMode(null);
                const infiniteZIndex = 999999;
                switch (type) {
                    case SHAPE.CIRCLE:
                        handleDrawComplete(overlay, SHAPE.CIRCLE, drawingManager)
                        break;
                    case SHAPE.RECTANGLE:
                        handleDrawComplete(overlay, SHAPE.RECTANGLE, drawingManager)
                        break;
                    case SHAPE.POLYGON:
                        handleDrawComplete(overlay, SHAPE.POLYGON, drawingManager)
                        break;
                }
            });
        }

    React.useEffect(() => {
        if (mapsApi) {
            const { map } = mapsApi
            if (loadingMapFilterSearch && map) {
                map.setOptions({
                    draggableCursor: "progress"
                })
                document.body.style.cursor = "progress"
            } else {
                document.body.style.cursor = ""
                map.setOptions({
                    draggableCursor: ""
                })
            }
        }
    }, [loadingMapFilterSearch, mapsApi])

    // Points of Interest Layers (Points)
    React.useEffect(() => {
        if (mapsApi) {
            const { map, maps } = mapsApi

            handleShowPOI(map, maps, selectedLayers)
        }
    }, [mapsApi, selectedLayers])

    React.useEffect(() => {
        if (markers.length) {
            displayMarkers()
        }
    }, [markers, selectedLayers])
    // --- END ---

    // School District Layers (Boundaries)
    React.useEffect(() => {
        if (mapsApi) {
            handleShowSchoolDistrict(mapsApi, schoolDistrictLayer)
        }
    }, [mapsApi, schoolDistrictLayer])

    React.useEffect(() => {
        if (schoolDistrictBoundaries.length) {
            displayBoundaries()
        }
    }, [schoolDistrictBoundaries, schoolDistrictLayer])
    // --- END ---

    // Subdivision Layers (Boundaries)
    React.useEffect(() => {
        if (mapsApi) {
            handleShowSubdivision(mapsApi, subdivisionLayer)
        }
    }, [mapsApi, subdivisionLayer])

    React.useEffect(() => {
        if (subdivisionBoundaries.length) {
            displayBoundaries()
        }
    }, [subdivisionBoundaries, subdivisionLayer])
    // --- END ---

    // --- School Layers (points) ---
    React.useEffect(() => {
        if (mapsApi) {
            debounced(handleShowSchools, { mapsApi, selectedSchoolLayers })
        }
    }, [tabChosen === 'all' && mapsApi, selectedSchoolLayers, currentZoomLevel].filter(Boolean))
    // --- END ---

    React.useEffect(() => {
        if (mapsApi) {
            const { map, maps } = mapsApi

            setFitBounds(map, maps, listings, drawShapes)
        }
    }, [mapsApi, listings, drawShapes])

    React.useEffect(() => {
        if (selectedListings.length === 0 && isUnselectActivated) {
            setIndividualListingCollection([]);
        }
    }, [selectedListings, isUnselectActivated])

    const handleShowSchools = async ({ map, maps }, selectedSchoolLayers?: ISchoolSelectors[]) => {
        if (!selectedSchoolLayers.length) {
            setMapSchools(map)
            schools = []
            setSchools(schools)
            return
        }

        const bounds = map.getBounds()

        const NE = bounds.getNorthEast()
        const SW = bounds.getSouthWest()
        const point1 = { latitude: NE.lat(), longitude: SW.lng() }
        const point2 = { latitude: SW.lat(), longitude: NE.lng() }

        try {
            const schoolsData = await schoolsApi.post({ point1, point2, schoolSelectors: selectedSchoolLayers })
            setMapSchools(null)
            schools = []

            schoolsData.map(({ name, latitude: lat, longitude: lng, street }) => {
                const infoWindow = new maps.InfoWindow()
                const marker = new maps.Marker({
                    map,
                    title: name,
                    optimized: true,
                    icon: {
                        url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/school-71.png',
                        scaledSize: {
                            width: 25,
                            height: 25
                        }
                    },
                    position: { lat, lng }
                })

                schools.push(marker)

                const customInfoWindowModalProps = {
                    title: name,
                    formatted_address: street
                }

                marker.addListener('mouseover', () => {
                    infoWindow.setContent(
                        customInfoWindowModal(customInfoWindowModalProps)
                    )
                    infoWindow.open({
                        anchor: marker,
                        map,
                        shouldFocus: false
                    })
                })

                marker.addListener('mouseout', () => {
                    infoWindow.close()
                })

                maps.event.addListener(map, 'zoom_changed', () => {
                    const zoom = map.getZoom()
                    marker.setLabel(zoom <= 15 ? null : {
                        text: name,
                        className: 'poi-marker-label',
                    })
                })
            })
            setSchools(schools)
        } catch (err) {
            console.error('Map > school layers:', err)
        }
    }

    const setMapSchools = (map): void => {
        schools.map(marker => {
            marker.setMap(selectedSchoolLayers.length && map ? map : null)
        })
    }

    const handleShowSchoolDistrict = async ({ map, maps }, schoolDistrictLayer) => {
        if (!schoolDistrictLayer) {
            setMapBoundaries(map)
            setSchoolDistrictBoundaries([])
            return
        }

        const bounds = map.getBounds()

        const NE = bounds.getNorthEast()
        const SW = bounds.getSouthWest()
        const point1 = { latitude: NE.lat(), longitude: SW.lng() }
        const point2 = { latitude: SW.lat(), longitude: NE.lng() }

        try {
            const schoolDistrictData = await schoolDistrictApi.post({ point1, point2 })
            const polygonBounds = new maps.LatLngBounds()

            schoolDistrictData.map(data => {
                const { geometry, geoid, name } = data
                const { stroke, fill } = colorRandomizer()

                const polygonCoords = coordinatesReshaper(geometry.coordinates)

                for (let i = 0; i < polygonCoords.length; i++) {
                    polygonBounds.extend(polygonCoords[i])
                }

                const boundary = new maps.Polygon({
                    paths: coordinatesReshaper(geometry.coordinates),
                    strokeColor: stroke,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: fill,
                    fillOpacity: 0.25
                })

                const markerLabel = new maps.Marker({
                    map,
                    label: {
                        text: name,
                        color: stroke,
                        className: 'boundaries-label'
                    },
                    optimized: true,
                    icon: {
                        path: maps.SymbolPath.CIRCLE,
                        scale: 0
                    },
                    position: getPolygonCentroid(coordinatesReshaper(geometry.coordinates))
                })

                schoolDistrictBoundaries.push({ geoid, boundary, markerLabel })
                schoolDistrictBoundaries = schoolDistrictBoundaries.filter((item, index, array) => array.findIndex(item2 => (item2.geoid === item.geoid)) === index)
                setSchoolDistrictBoundaries(schoolDistrictBoundaries)
            })
        } catch (err) {
            console.error(err)
        }
    }

    const handleShowSubdivision = async ({ map, maps }, subdivisionLayer) => {
        if (!subdivisionLayer) {
            setMapBoundaries(map)
            setSubdivisionBoundaries([])
            return
        }

        const bounds = map.getBounds()

        const NE = bounds.getNorthEast()
        const SW = bounds.getSouthWest()
        const point1 = { latitude: NE.lat(), longitude: SW.lng() }
        const point2 = { latitude: SW.lat(), longitude: NE.lng() }

        try {
            const subdivisionData = await subdivisionApi.post({ point1, point2 })
            const polygonBounds = new maps.LatLngBounds()

            subdivisionData.map(data => {
                const { geometry, id: geoid, name } = data
                const { stroke, fill } = colorRandomizer()

                const polygonCoords = coordinatesReshaper(geometry.coordinates)

                for (let i = 0; i < polygonCoords.length; i++) {
                    polygonBounds.extend(polygonCoords[i])
                }

                const boundary = new maps.Polygon({
                    paths: coordinatesReshaper(geometry.coordinates),
                    strokeColor: stroke,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: fill,
                    fillOpacity: 0.25
                })

                const markerLabel = new maps.Marker({
                    map,
                    label: {
                        text: name,
                        color: stroke,
                        className: 'boundaries-label'
                    },
                    optimized: true,
                    icon: {
                        path: maps.SymbolPath.CIRCLE,
                        scale: 0
                    },
                    position: getPolygonCentroid(coordinatesReshaper(geometry.coordinates))
                })

                subdivisionBoundaries.push({ geoid, boundary, markerLabel })
                subdivisionBoundaries = subdivisionBoundaries.filter((item, index, array) => array.findIndex(item2 => (item2.geoid === item.geoid)) === index)
                setSubdivisionBoundaries(subdivisionBoundaries)
            })
        } catch (err) {
            console.error(err)
        }
    }

    // Getting the centroid especially for irregular and comples polygons
    const getPolygonCentroid = (points) => {
        const lat0 = points[0].lat
        const lng0 = points[0].lng

        let lat = 0, lng = 0, twiceArea = 0
        let prev = points[points.length - 1]

        for (const next of points) {
            const lat1 = prev.lat - lat0
            const lng1 = prev.lng - lng0
            const lat2 = next.lat - lat0
            const lng2 = next.lng - lng0
            const a  = lat1 * lng2 - lat2 * lng1

            twiceArea += a
            lat += (lat1 + lat2) * a
            lng += (lng1 + lng2) * a

            prev = next
        }

        const factor = 3 * twiceArea
        lat /= factor
        lng /= factor

        return { lat: lat + lat0, lng: lng + lng0 }
    }

    const coordinatesReshaper = (coords: { latitude: number, longitude: number }[]) => coords.map(({ latitude: lat, longitude: lng }) => ({ lng, lat }))

    const setMapBoundaries = (map): void => {
        schoolDistrictBoundaries.map(({ boundary, markerLabel }) => {
            markerLabel.setMap(schoolDistrictLayer ? map : null)
            boundary.setMap(schoolDistrictLayer ? map : null)
        })
        subdivisionBoundaries.map(({ boundary, markerLabel }) => {
            markerLabel.setMap(subdivisionLayer ? map : null)
            boundary.setMap(subdivisionLayer ? map : null)
        })
    }

    const displayBoundaries = (): void => {
        if (mapsApi) {
            setMapBoundaries(mapsApi.map)
        }
    }

    const setMapMarkers = (map): void => {
        let clusteredLayers = selectedLayers

        if (selectedLayers.includes(POI_TYPES.HOSPITAL)) {
            clusteredLayers = [...clusteredLayers, 'health']
        }
        if (selectedLayers.includes(POI_TYPES.BANK)) {
            clusteredLayers = [...clusteredLayers, 'atm']
        }
        if (selectedLayers.includes(POI_TYPES.SUPERMARKET)) {
            clusteredLayers = [...clusteredLayers, 'grocery']
        }
        if (selectedLayers.includes(POI_TYPES.TRANSPORTATION)) {
            clusteredLayers = [...clusteredLayers, 'bus_station', 'train_station']
        }

        markers.map((marker, index) => {
            const isFound = marker.types.filter(e => clusteredLayers.includes(e))
            marker.pin.setMap(isFound.length && selectedLayers.length ? map : null)
        })
    }

    const displayMarkers = (): void => {
        if (mapsApi) {
            setMapMarkers(mapsApi.map)
        }
    }

    const handleShowPOI = (map, mapsApi, selectedLayers) => {
        if (!selectedLayers.length) {
            setMapMarkers(map)
            setMarkers([])
            return
        }

        const service = new mapsApi.places.PlacesService(map)
        const placesRequest = {
            location: {
                lat: getGeoLocationAverage.lat,
                lng: getGeoLocationAverage.lng
            },
            query: selectedLayers[selectedLayers.length - 1]
        }

        service.textSearch(
            placesRequest,
            (res, status, pagination) => nearbySearchCallback(res, status, map, mapsApi, pagination)
        )
    }

    const nearbySearchCallback = (results, status, map, mapsApi, pagination) => {
        if ((status === mapsApi.places.PlacesServiceStatus.OK) && results) {
            results.map((result: any) => {
                createPOIMarker(result, map, mapsApi)
            })
        }
    }

    const createPOIMarker = (poi: any, map: any, mapsApi: any) => {
        const {
            geometry, icon, place_id, name,
            formatted_address, icon_background_color
        } = poi

        let desiredIcon: string = icon
        let desiredBackground: string = icon_background_color

        const infoWindow = new mapsApi.InfoWindow()
        const geoLocation = geometry?.location

        if (!geometry || !geoLocation) return

        poi.types.map((type: string) => {
            switch (type) {
                case 'health': {
                    if (selectedLayers.includes(POI_TYPES.HOSPITAL)) {
                        desiredIcon = POI_ICONS.HOSPITAL
                    }
                    break
                }
                case POI_TYPES.HOSPITAL: {
                    if (selectedLayers.includes(POI_TYPES.HOSPITAL)) {
                        desiredIcon = POI_ICONS.HOSPITAL
                    }
                    break
                }
                case POI_TYPES.RESTAURANT: {
                    if (selectedLayers.includes(POI_TYPES.RESTAURANT)) {
                        desiredIcon = POI_ICONS.RESTAURANT
                    }
                    break
                }
                case 'grocery': {
                    if (selectedLayers.includes(POI_TYPES.SUPERMARKET)) {
                        desiredIcon = POI_ICONS.SUPERMARKET
                    }
                    break
                }
                case POI_TYPES.SUPERMARKET: {
                    if (selectedLayers.includes(POI_TYPES.SUPERMARKET)) {
                        desiredIcon = POI_ICONS.SUPERMARKET
                    }
                    break
                }
                case POI_TYPES.TRANSPORTATION: {
                    if (selectedLayers.includes(POI_TYPES.TRANSPORTATION)) {
                        desiredIcon = POI_ICONS.TRANSPORTATION
                        desiredBackground = POI_BACKGROUND_COLORS.TRANSPORTATION
                    }
                    break
                }
            }
        })

        const marker = new mapsApi.Marker({
            map,
            title: name,
            optimized: true,
            icon: {
                url: desiredIcon,
                scaledSize: {
                    width: 25,
                    height: 25
                }
            },
            place: {
                placeId: place_id,
                location: geoLocation
            }
        })

        markers.push({ pin: marker, types: poi.types })
        markers = markers.filter((item, index, array) => array.findIndex(item2 => (item2.pin.place.placeId === item.pin.place.placeId)) === index)
        setMarkers(markers)

        const customInfoWindowModalProps = {
            title: name,
            formatted_address
        }

        marker.addListener('mouseover', () => {
            infoWindow.setContent(
                customInfoWindowModal(customInfoWindowModalProps)
            )
            infoWindow.open({
                anchor: marker,
                map,
                shouldFocus: false
            })
        })

        marker.addListener('mouseout', () => {
            infoWindow.close()
        })

        mapsApi.event.addListener(map, 'zoom_changed', () => {
            const zoom = map.getZoom()
            marker.setLabel(zoom <= 15 ? null : {
                text: name,
                className: 'poi-marker-label',
                color: desiredBackground
            })
        })

        marker.setMap(null)
    }

    const customInfoWindowModal = ({ title, formatted_address }) => (
        `<div className='info-window'>
            <div className='content'>
                <div className='title'>
                    <span><b>${title}</b></span>
                </div>
                <div className='street-address'>
                    <span>${formatted_address}</span>
                </div>
            </div>
        </div>`
    )

    const onOpen = () => {
        setIsOpen(true)
    }

    const getEstimateLongitudeFocus = () => ({ lng: centerFocus.lng - 0.0005, lat: centerFocus.lat + 0.005 })

    const getGeoLocationAverage = React.useMemo(() => {
        const geoLocationSum = listings.reduce((prev, curr) => ({
            ...prev,
            latitude: prev.latitude + curr.latitude,
            longitude: prev.longitude + curr.longitude
        }), { latitude: 0, longitude: 0 })

        return {
            lat: geoLocationSum.latitude / listings.length,
            lng: geoLocationSum.longitude / listings.length
        }
    }, [listings])

    const colorRandomizer = () => {
        const color = `#${Math.floor(Math.random()*16777215).toString(16)}`

        return { fill: color, stroke: color }
    }

    let mouseDrag = false

    const handleCloseModal = (wasMouseDragged) => {
        if (wasMouseDragged) return

        onActiveModalId(0)
        onActiveTooltipId(0)
        hoverListingAction(null)
        setCurrentPage(1)
    }

    const retrieveDrawnShapes = (map : any, maps : any) => {
        if (drawShapes) {
            let drawings = [];
            const infiniteZIndex = 999999;
            drawShapes.map(shape => {
                if (shape && shape.hasOwnProperty('radius')) {
                    const circleShape = new maps.Circle({
                        strokeWeight: shape.StrokeWeight,
                        fillColor: shape.fillColor,
                        fillOpacity: shape.fillOpacity,
                        editable: true,
                        map,
                        center: {lat: shape.center.lat(), lng: shape.center.lng()},
                        radius: shape.radius,
                        zIndex: shape.zIndex,
                        uniqueShapeId: shape.uniqueShapeId,
                        strokeColor: shape.strokeColor
                    });
                    drawings.push(circleShape);
                    maps.event.addListener(circleShape, "click",  (event) => {
                        circleShape.setOptions({ zIndex: circleShape.zIndex + infiniteZIndex })
                        shapeMouseOver({ lat: circleShape.center.lat(), lng: circleShape.center.lng()}, circleShape, shapeOptionLatLng)
                    });
                } else if (shape && shape.hasOwnProperty('bounds')) {
                    const rectangleShape = new maps.Rectangle({
                        strokeWeight: shape.StrokeWeight,
                        fillColor: shape.fillColor,
                        fillOpacity: shape.fillOpacity,
                        editable: true,
                        map,
                        bounds: shape.bounds,
                        zIndex: shape.zIndex,
                        uniqueShapeId: shape.uniqueShapeId,
                        strokeColor: shape.strokeColor
                    });
                    drawings.push(rectangleShape);
                    maps.event.addListener(rectangleShape, "click",  (event) => {
                        rectangleShape.setOptions({ zIndex: rectangleShape.zIndex + infiniteZIndex })
                        shapeMouseOver(
                            { lat: rectangleShape.getBounds().getCenter().lat(), lng: rectangleShape.getBounds().getCenter().lng() },
                            rectangleShape,
                            shapeOptionLatLng
                        )
                    });
                } else if (shape instanceof maps.Polygon) {
                    const polygonShape = new maps.Polygon({
                        strokeWeight: shape.StrokeWeight,
                        fillColor: shape.fillColor,
                        fillOpacity: shape.fillOpacity,
                        editable: true,
                        map,
                        paths: shape.latLngs,
                        zIndex: shape.zIndex,
                        uniqueShapeId: shape.uniqueShapeId,
                        strokeColor: shape.strokeColor
                    });
                    drawings.push(polygonShape);
                    maps.event.addListener(polygonShape, "click",  (event) => {
                        polygonShape.setOptions({ zIndex: polygonShape.zIndex + infiniteZIndex })
                        shapeMouseOver(
                            { lat: polygonCenter(polygonShape).centerX, lng: polygonCenter(polygonShape).centerY },
                            polygonShape,
                            shapeOptionLatLng
                        )
                    });
                }
            })
            setRetrieveDrawings(drawings);
        }
    }

    const setFitBounds = (map : any, maps : any, listings: any, drawShapes : any) => {
        if (map) {
            if (drawShapes.length === 0) {
                const bounds = new maps.LatLngBounds();
                  listings.map(marker => {
                  if (marker.latitude !== 0 && marker.longitude !== 0 ) {
                      bounds.extend({
                          lat: marker.latitude,
                          lng: marker.longitude,
                      });
                  }
                });
                map.fitBounds(bounds);
            }
        }
    }

    const handleIndividualListing = (individualListing : IListing) => {

        if (!individualListingCollection.includes(individualListing)) {
            individualListingCollection.push(individualListing);
        }

        setIndividualListingCollection(individualListingCollection);
        handleAllSelectedListings([individualListing], true);
    }

    const SINGLE_SELECT_MESSAGE = 'See listing details and gain unlimited access to owner-sourced Rental Beast inventory by upgrading to Rental Beast Pro.'
    const ALL_TAB_MESSAGE = 'All MLS pins are selected. See listing details and gain unlimited access to owner-sourced Rental Beast inventory by upgrading to Rental Beast Pro.';
    const RB_TAB_MESSAGE = 'See listing details and gain unlimited access to owner-sourced Rental Beast inventory by upgrading to Rental Beast Pro.';

    const DIALOG_MESSAGE_SELECT = React.useMemo(() : string | null => {
        if (multiSelect) {
            return tabChosen == 'all' ? ALL_TAB_MESSAGE : RB_TAB_MESSAGE
        }
         return SINGLE_SELECT_MESSAGE
    }, [singleSelect, multiSelect, tabChosen])
    const {agentId, agentInfo} = useContext(AgentUserContext);
    const [newClientModal, setNewClientModal] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const clientContactInfo = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        status: "active",
        name: "",
        client_type: "renter",
        id: 0
    }
    const [applicants, setApplicants] = useState([clientContactInfo]);
    const [newClientIndex, setNewClientIndex] = useState(null);
    const [clients, setClients] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const dialogClassess = useStyles();
        const openNewClientModal = (index) => {
            setNewClientIndex(index)
            // setOpenModal(false)
            setNewClientModal(true)
        }

        const closeNewClientModal = () => {
            setNewClientModal(false)
            setOpenModal(true)
            setNewClientIndex(null)
        }

        const closeModal = () => {
            setOpenModal(false)
            setApplicants([clientContactInfo])
        }

        const openInviteApplicationModal = (row) => {
            setOpenModal(true)
            setSelectedRow(row)
        }

        const selectClient = (index = null, value) => {
            const [firstName, lastName] =  value.value ? value.value.name.split(' ') : value.name.split(' ');
            const clientDetails = {
                first_name: firstName,
                last_name:  lastName,
                email: value.value.main_email,
                phone: value.value.main_phone, // Make sure this is correct
                name: value.value.name || `${value.value.first_name} ${value.value.last_name}`,
                status: "Active",
                client_type: "renter",
                id: value.value.id
            };
            const currentIndex = newClientIndex ? newClientIndex : index
            setApplicants(applicants.map((applicant, i) => i === currentIndex ? { ...applicant, ...clientDetails } : applicant));
        };

        const getClients = async () => {
            if (!agentId) return
            try {
                const response = await agentClientsApi.getList({
                    agentId
                })
                setClients(response)
            } catch (error) {
                console.log('error', error)
            }
        }

        useEffect(() => {
            getClients()
        }, [agentId, agentInfo])

        const addNewAppicant = () => {
            setApplicants([...applicants, clientContactInfo])
        }

        const removeApplicant = () => {
          if (applicants.length > 1) {
            setApplicants(applicants.slice(0, -1))
          } else {
              setApplicants([...applicants.slice(0, -1), clientContactInfo])
          }
        };

        const inviteApplicants = async () => {
            const [firstName, lastName] = agentInfo.full_name.split(' ');

            const applicantLists = applicants.map(applicant => ({
                firstName: applicant.first_name,
                lastName: applicant.last_name,
                email: applicant.email,
                confirmEmail: applicant.email,
                phoneNumber: applicant.phone
            }));

            const landlord = {
                email: agentInfo.email,
                confirmEmail: agentInfo.email,
                first_name: firstName,
                last_name: lastName,
                phone: agentInfo.phone,
            };

            const selectedListing = {
                area: selectedRow.square_footage,
                baths: String(selectedRow.bathrooms),
                beds:  String(selectedRow.beds_count),
                city: selectedRow.city,
                listing_id: selectedRow.apartment_id,
                rent: selectedRow.rent,
                state: getStateAbbr(selectedRow.state, countryCode),
                street_address: selectedRow.address,
                unit_no: selectedRow.apt_number,
                zip: selectedRow.zip,
            }

            const payload = {
                applicants: applicantLists,
                landlord,
                listing: selectedListing,
                partney_key:  agentInfo.partner_key,
                role: "broker"
            }
            const response = await axios.post(`${window.location.origin}/free_tenant_screening`, payload)

            if (response) {
                closeModal()
            }

        }
    return (
        <>
            <Dialog
                open={isOpen}
                onClose={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen(false);
                }}
                fullWidth
                maxWidth='xs'
            >
                <div style={{ padding: 10, textAlign: 'center' }}>
                    <span>&#x26A0; </span>
                    <span>{DIALOG_MESSAGE_SELECT}</span>
                </div>
            </Dialog>
            <div className="loading-map-icon"></div>
            <div className='map-container' id='map-section'>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: googleMapApiKey,
                        libraries: ["drawing", 'places']
                    }}
                    onChildMouseDown={() => mouseDrag = false}
                    onChildMouseMove={() => mouseDrag = true}
                    onChildClick={() => handleCloseModal(mouseDrag)}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => {
                        initGoogleMapApi(map, maps)
                        setMapsApi({ map, maps })
                        setMultiSelectDrawManager({map, maps})
                        retrieveDrawnShapes(map, maps);
                    }}
                    center={centerFocus ? getEstimateLongitudeFocus() : defaultProps.center}
                    onChildMouseLeave={() => {
                        hoverListingAction(0)
                        onActiveTooltipId(0)
                    }}
                    hoverDistance={2000}
                    onChange={({ zoom }) => setCurrentZoomLevel(zoom)}
                    options={(maps) => ({
                        zoomControl: true,
                        zoomControlOptions: {
                            position: maps.ControlPosition.RIGHT_TOP,
                        },
                        disableDoubleClickZoom: preventZoomIn,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: maps.ControlPosition.TOP_RIGHT,
                        },
                        streetViewControl: true,
                        streetViewControlOptions: {
                            position: maps.ControlPosition.RIGHT_TOP,
                        },
                        mapTypeId: mapSetting || "roadmap",
                        gestureHandling: "greedy",
                        clickableIcons: false
                    })}
                >
                    {mapsApi !== undefined && shapeOptionLatLng.lat !== null &&
                        shapeOptionLatLng.lng !== null && (
                            <ShapeOptions
                                lat={shapeOptionLatLng.lat}
                                lng={shapeOptionLatLng.lng}
                                hoveredShapeId={hoveredShapeId}
                                handleExcludeListing={handleExcludeListing}
                                handleDeleteListing={handleDeleteListing}
                                shapeMouseOut={shapeMouseOut}
                                retrieveDrawings={retrieveDrawings}
                            />
                        )}
                    {mapsApi !== undefined && listings.length > 0 &&
                        listings.map((listing) => {
                            return (
                                <ListingMapResultPins
                                    key={listing.apartment_id}
                                    lat={listing.latitude}
                                    lng={listing.longitude}
                                    status={listing.status}
                                    rent={listing.rent}
                                    listing={listing}
                                    aptCount={listing.apt_count}
                                    hoveredListingId={hoveredListingId}
                                    listingId={listing.apartment_id}
                                    hoverListingAction={hoverListingAction}
                                    isSingleCheck={isSingleCheck}
                                    bookmarks={bookmarks}
                                    handleBookmark={handleBookmark}
                                    activeTooltipId={activeTooltipId}
                                    onActiveTooltipId={onActiveTooltipId}
                                    activeModalId={activeModalId}
                                    onActiveModalId={onActiveModalId}
                                    isAddressDisplay={isAddressDisplay}
                                    totalListingCount={totalListingCount}
                                    onPreventZoomIn={setPreventZoomIn}
                                    onOpen={onOpen}
                                    listingsMapArea={listingsMapArea}
                                    onCenterFocus={setCenterFocus}
                                    currentPage={currentPage}
                                    onSetCurrentPage={setCurrentPage}
                                    setMultiSelect={setMultiSelect}
                                    setSingleSelect={setSingleSelect}
                                    onIndividualListing={handleIndividualListing}
                                    domAndRentChanges={{ dom: listing.dom, rentChanges: listing.rent_changes }}
                                    openInviteApplicationModal={openInviteApplicationModal}
                                />
                            );
                        })}
                </GoogleMapReact>
                <Dialog
                        open={openModal}
                        onClose={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenModal(false);
                            setNewClientModal(false)
                            setApplicants([clientContactInfo])
                        }}
                        maxWidth="xs"
                        fullWidth
                        PaperProps={{
                            style: {
                                padding: 0,
                                margin: 0,
                                position: 'relative'
                            },
                        }}
                    >
                        {
                            newClientModal ? (
                                <NewClientModal
                                    dialogClassess={dialogClassess}
                                    closeModal={closeNewClientModal}
                                    clientContactInfo={clientContactInfo}
                                    selectClient={selectClient}
                                    getClients={getClients}
                                    newClientIndex={newClientIndex}
                                />
                            ) : (
                                <ApplicationInviteModal
                                    selectedRow={selectedRow}
                                    closeModal={closeModal}
                                    dialogClassess={dialogClassess}
                                    clients={clients}
                                    selectClient={selectClient}
                                    selectedClient={selectedClient}
                                    openNewClientModal={openNewClientModal}
                                    applicants={applicants}
                                    addNewAppicant={addNewAppicant}
                                    removeApplicant={removeApplicant}
                                    inviteApplicants={inviteApplicants}
                                />
                            )
                        }
                </Dialog>
            </div>
        </>
    );
})
