import React, { useContext, useEffect, useState } from "react";
import "./floorplan_details.scss";
import applyNowLogo from "../../../../../../../assets/images/broker_portal/apply_now_logo.png"
import { ListingActionButtons } from "../../../../listing_action_buttons/listing_action_buttons";
import { getUserId } from "../../../../../../services/session";
import { userBookmarksApi } from "../../../../../../services/rails_api/user/bookmarks";
import { IListing } from "../../../../../../services/api/listing";
import {
  Dialog,
  makeStyles,
  Tab,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { RbButton } from "../../../../../components/rb_button/rb_button";
import moment from "moment";
import { isMobile } from "../../../../../utils/device_info";
import { RBCheckbox } from "../../../../../components/rb_checkbox/rb_checkbox";
import { SelectedListingsContext } from "../../../selected_listings_context";
import { BookmarksContext } from "../../../bookmarks_context";
import { AgentUserContext } from "../../../agent_user_context";
import { ShareDialog } from "../../../../../components/share_dialog/ShareDialog";
import ApplicationInviteModal from "../../../../broker_portal/applications/application_invite_modal";
import axios from "axios";
import { agentClientsApi } from "../../../../../../services/rails_api/agent/clients";
import NewClientModal from "../../../../broker_portal/applications/new_client_modal";
import { getStateAbbr } from '../../../../../utils/get_state_abbr';

const getListingPriceLabel = (listing: IListing) => {
  if (listing.floor_plan_id && listing.floor_plan_min_rent) {
    return `$${listing.floor_plan_min_rent} ${
      listing.floor_plan_max_rent ? "- $" + listing.floor_plan_max_rent : null
    }`;
  }
  return listing.rent ? `$${listing.rent}` : null;
};

const getAvailableDateLabel = (listing: IListing) => {
  const year = moment(listing.date_available).year();
  const currentYear = moment().year();
  if (
    moment().format("YYYY-MM-DD") ===
    moment(listing.date_available).format("YYYY-MM-DD")
  ) {
    return "Available Now";
  }
  if (year === currentYear) {
    return `Available on ${moment(listing.date_available).format("MMM DD")}`;
  } else {
    return `Available on ${moment(listing.date_available).format(
      "MMM DD YYYY"
    )}`;
  }
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
         padding: '0 !important',
         margin: '0', // Optional: Adjust margin if needed
     },
   dialogTitle: {
       padding: '0 !important',
   },
   dialogContent: {
       minHeight: 300
   },
   dialogActions: {
     justifyContent: 'center',
     marginBottom: 15
   },
   closeButton: {
       position: 'absolute',
       right: theme.spacing(1),
       top: theme.spacing(1),
       color: theme.palette.grey[500],
   },
   modalHeaderContainer: {
       padding: '16px 24px',
   },
   hrContainer: {
       padding: '0 24px',
   },
   hrDivider: {
       margin: 0,
   }
}));

export const FloorPlanDetails = React.memo(
  function FloorPlanDetailsComponent(props: IFloorPlanDetails) {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [shareListing, setShareListing] = useState(null);

    const { toggleBookmarks } = useContext(BookmarksContext);
    const {
      handleSelectedListing,
      selectedListings,
      isMlsAgentAndNonMlsListing,
      isGatewayAccessibleListing,
      getSelectedListingIds,
      areShowingListings,
      isMLSListing,
      isRBGListing,
      isMlsAgentAndEmptyDisclaimer
    } = useContext(SelectedListingsContext);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [newClientModal, setNewClientModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const clientContactInfo = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      status: "active",
      name: "",
      client_type: "renter",
      id: 0
    }
    const [applicants, setApplicants] = useState([clientContactInfo]);
    const dialogClassess = useStyles();
    const [newClientIndex, setNewClientIndex] = useState(null);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);


    const { isFullAgent, isMlsAgent, agentId, agentInfo, countryCode } = useContext(AgentUserContext);
    const {handleToggleUpgradeModal, isAddressDisplay} = props
    const [formattedPlans, setFormattedPlans] = useState(
      [] as IFormattedFloorplans[]
    );
    const [hasFloorplans, setHasFloorplans] = useState(false);
    const [currentTab, setCurrentTab] = useState("-1");

    const handleDialogClose = () => {
      setShareDialogOpen(false);
    };


    const handleShareClick = (listing) => {
      setShareListing(listing);
      setShareDialogOpen(true);
    };

    const showOrHideShareLinkIcon = (listing) => {
      if (listing.apt_count > 1) {
        return;
      }
      if (
        (isFullAgent() && listing.is_listing_free) ||
        listing.is_agent_listing
      ) {
        return (
          <i
            className="fas fa-share share-link"
            onClick={() => handleShareClick(listing)}
          />
        );
      } else if (isMlsAgent() && listing.is_agent_listing) {
        return (
          <i
            className="fas fa-share share-link"
            onClick={() => handleShareClick(listing)}
          />
        );
      }
    };

    const formatPlans = async () => {
      const userId = await getUserId();
      let bookmarkIds = [];
      const bookmarkRes = await userBookmarksApi.get(userId);
      if (bookmarkRes && bookmarkRes.data && bookmarkRes.data.listing_ids) {
        bookmarkIds = bookmarkRes.data.listing_ids;

        const selectedListings = [];
        bookmarkIds.forEach((id) =>
          selectedListings.push({ listingId: id, groupListingId: id })
        );
      }

      const plans = props.listings.map((listing) => {
        return {
          ...listing,
          bookmarked: bookmarkIds.includes(listing.apartment_id),
        };
      });

      const hasPlans = plans.find((plan) => {
        return plan.floor_plan_id;
      });

      setHasFloorplans(Boolean(hasPlans));
      setFormattedPlans(plans);
    };

    const filterSelectedTab = (listing) => {
      if (currentTab == "-1") {
        return listing;
      } else if (currentTab == "0") {
        return listing.bedrooms === 0;
      } else if (currentTab == "1") {
        return listing.bedrooms === 1;
      } else if (currentTab == "2") {
        return listing.bedrooms === 2;
      } else if (currentTab == "3") {
        return listing.bedrooms >= 3;
      }
    };

    useEffect(() => {
      formatPlans();
    }, [props.listings]);

    const handleClick = (e, listing: IListing) => {
      if (e.target === e.currentTarget) {
        if (isFullAgent() || isGatewayAccessibleListing(listing)) {
          const url = `/broker_portal/listing_details/${listing.apartment_id}`;
          window.open(url, "_blank");
        }
      }
    };

    const onBookmarkFlagClick = async (e, listing) => {
      const res = await toggleBookmarks(e, listing);
      formatPlans();
    };

    const tabs = [{ label: "All", value: "-1" }];
    props.listings.forEach((listing) => {
      if (listing.bedrooms == "0" && !tabs.find((tab) => tab.value == "0")) {
        tabs.push({ label: "Studio", value: "0" });
      } else if (
        listing.bedrooms == "1" &&
        !tabs.find((tab) => tab.value == "1")
      ) {
        tabs.push({ label: "1 Bed", value: "1" });
      } else if (
        listing.bedrooms == "2" &&
        !tabs.find((tab) => tab.value == "2")
      ) {
        tabs.push({ label: "2 Beds", value: "2" });
      } else if (
        listing.bedrooms == "3" &&
        !tabs.find((tab) => tab.value == "3")
      ) {
        tabs.push({ label: "3+ Beds", value: "3" });
      }
    });

    tabs.sort((prev, curr) => {
      return parseInt(prev.value) - parseInt(curr.value);
    });

    const getBedsLabel = (beds) => {
      if (beds == 0) {
        return "Studio";
      } else {
        return `${beds} Bed${parseInt(beds) > 1 ? "s" : ""}`;
      }
    };

    const handleRowClick = (listing) => {
      if (isMlsAgentAndNonMlsListing(listing) ||
          isMlsAgentAndEmptyDisclaimer(listing)
        ) {
          if (!isAddressDisplay) {
            return
          }
          handleToggleUpgradeModal(listing.apartment_id)
      }
    }

    const handleRowCheck = (listing, checked) => {
      handleSelectedListing(listing.apartment_id, !checked, props.groupListingId, props.listings, props.pageNumber);
    };

    const getActionButtons = (listing) => {
        if (isMlsAgentAndNonMlsListing(listing) || (isMlsAgent() && listing.listing_source_type === "Off-MLS")) {
            return (
              <div className="row">
                <div className="col-12">
                  <ListingActionButtons
                    selectedListingIds={[listing.apartment_id]}
                    excludeBookmark={true}
                    toggleBookmarks={toggleBookmarks}
                    isDisabled={false}
                    listing={listing}
                    includeFacebookShare={false}
                    excludeClaim={isRBGListing(listing)}
                    excludeCMA={true}
                    excludeEmail={true}
                    excludePrint={true}
                    excludeShare={true}
                  />
                </div>
              </div>
            );
        } else {
            const hideClaimButtonOnMlsListing = isMLSListing(listing) ? true : false;
            const selectedListingIds = getSelectedListingIds();
            return (
              <div className="row">
                <div className="col-12">
                  <ListingActionButtons
                    selectedListingIds={selectedListingIds}
                    isDisabled={!selectedListingIds.includes(listing.apartment_id)}
                    excludeBookmark={listing.bookmarked}
                    toggleBookmarks={toggleBookmarks}
                    listing={listing}
                    includeRemoveListing={areShowingListings}
                    excludeClaim={hideClaimButtonOnMlsListing || isRBGListing(listing)}
                    excludeCMA={false}
                    includeFacebookShare={true}
                    excludeEmail={false}
                    excludePrint={false}
                    excludeShare={false}
                  />
                </div>
              </div>
            );
        }
    };

    const getCheckedValue = (listing) => {
      return selectedListings.some(
        (selectedListing) => selectedListing.listingId === listing.apartment_id
      );
    };

    const openInviteApplicationModal = (row) => {
      setOpenModal(true)
      setSelectedRow(row)
    }

    const showOrHideApplyNowIcon = (listing) => {
      return (listing?.use_apply_now && (listing?.status === 'Active' || listing?.status === 'Application Pending')) && (
        <img
          width="30"
          src={applyNowLogo}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            openInviteApplicationModal(listing)
          }}
        />
      )
    };

    const openNewClientModal = (index) => {
      setNewClientIndex(index)
      // setOpenModal(false)
      setNewClientModal(true)
    }

    const closeModal = () => {
      setOpenModal(false)
      setApplicants([clientContactInfo])
    }

    const closeNewClientModal = () => {
      setNewClientModal(false)
      setOpenModal(true)
      setNewClientIndex(null)
    }

    const getClients = async () => {
      if (!agentId) return
      try {
          const response = await agentClientsApi.getList({
              agentId
          })
          setClients(response)
      } catch (error) {
          console.log('error', error)
      }
    }

    useEffect(() => {
        getClients()
    }, [agentId, agentInfo])

    const selectClient = (index = null, value) => {
      const [firstName, lastName] =  value.value ? value.value.name.split(' ') : value.name.split(' ');
      const clientDetails = {
          first_name: firstName,
          last_name:  lastName,
          email: value.value.main_email,
          phone: value.value.main_phone, // Make sure this is correct
          name: value.value.name || `${value.value.first_name} ${value.value.last_name}`,
          status: "Active",
          client_type: "renter",
          id: value.value.id
      };
      const currentIndex = index
      setApplicants(applicants.map((applicant, i) => i === currentIndex ? { ...applicant, ...clientDetails } : applicant));
    };

    const addNewAppicant = () => {
      setApplicants([...applicants, clientContactInfo])
    }

    const removeApplicant = () => {
      if (applicants.length > 1) {
        setApplicants(applicants.slice(0, -1))
      } else {
          setApplicants([...applicants.slice(0, -1), clientContactInfo])
      }
    };

    const inviteApplicants = async () => {
      const [firstName, lastName] = agentInfo.full_name.split(' ');

      const applicantLists = applicants.map(applicant => ({
          firstName: applicant.first_name,
          lastName: applicant.last_name,
          email: applicant.email,
          confirmEmail: applicant.email,
          phoneNumber: applicant.phone
      }));

      const landlord = {
          email: agentInfo.email,
          confirmEmail: agentInfo.email,
          first_name: firstName,
          last_name: lastName,
          phone: agentInfo.phone,
      };
      const selectedListing = {
          area: selectedRow.square_footage,
          baths: String(selectedRow.bathrooms),
          beds:  String(selectedRow.beds_count),
          city: selectedRow.city,
          listing_id: selectedRow.apartment_id,
          rent: selectedRow.rent,
          state: getStateAbbr(selectedRow.state, countryCode),
          street_address: selectedRow.address,
          unit_no: selectedRow.apt_number,
          zip: selectedRow.zip,
      }

      const payload = {
          applicants: applicantLists,
          landlord,
          listing: selectedListing,
          partney_key: agentInfo.partner_key,
          role: "broker"
      }
      const response = await axios.post(`${window.location.origin}/free_tenant_screening`, payload)

      if (response) {
          closeModal()
      }

    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <Tabs value={currentTab} onChange={(e, val) => setCurrentTab(val)}>
            {tabs.map((tab) => (
              <Tab key={tab.label} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" />
                <TableCell align="left" />
                <TableCell align="left">Beds</TableCell>
                <TableCell align="left">Baths</TableCell>
                <TableCell align="left">Rent</TableCell>
                <TableCell align="left">Units</TableCell>
                <TableCell align="left">Available</TableCell>
                <TableCell align="left">Status</TableCell>
                {/*<TableCell align="left">Owner Pays Fee</TableCell>*/}
                {/*<TableCell align="left">Min Commission</TableCell>*/}
                <TableCell align="left" />
                <TableCell align="left" />
                <TableCell align="left" />
              </TableRow>
            </TableHead>

            <TableBody>
              {!isMobile()
                ? formattedPlans.filter(filterSelectedTab).map((listing) => {
                    const checked = getCheckedValue(listing);
                    return (
                      <TableRow
                        key={listing.apartment_id}
                        className="zebra-stripes"
                        onClick={() => handleRowClick(listing)}
                      >
                        <TableCell align="left">
                          {listing.bookmarked ? (
                            <i
                              onClick={(e) =>
                                onBookmarkFlagClick(e, [listing.apartment_id])
                              }
                              className="fas fa-bookmark floorplan__title--bookmark"
                            />
                          ) : null}
                        </TableCell>
                        <TableCell align="left">
                          {isMlsAgentAndNonMlsListing(listing) || (isMlsAgent() && listing.listing_source_type === "Off-MLS") ? null : (
                            <RBCheckbox
                              name={listing.apartment_id.toString()}
                              checked={checked}
                              handleChange={(changed) =>
                                handleRowCheck(listing, checked)
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {getBedsLabel(listing.bedrooms)}
                        </TableCell>
                        <TableCell align="left">
                          {listing.bathrooms} Bath
                          {parseFloat(listing.bathrooms) > 1 ? "s" : null}
                        </TableCell>
                        <TableCell align="left">
                          {getListingPriceLabel(listing)}{" "}
                        </TableCell>
                        <TableCell align="left">
                          {listing.floor_plan_units_available || 1} Unit
                          {listing.floor_plan_units_available > 1 ? "s" : null}
                        </TableCell>
                        {hasFloorplans ? (
                          <TableCell align="left">
                            {listing.floor_plan_name}
                          </TableCell>
                        ) : null}
                        <TableCell align="left">
                          {getAvailableDateLabel(listing)}
                        </TableCell>
                        <TableCell align="left">{listing.status}</TableCell>
                        {/*<TableCell align="left">{listing.owner_pay}</TableCell>*/}
                        {/*<TableCell align="left">{listing.long_term_commission ? `$${listing.long_term_commission}` : "--"}</TableCell>*/}
                        <TableCell align="right">
                          {getActionButtons(listing)}
                        </TableCell>
                        <TableCell align="right">{showOrHideApplyNowIcon(listing)}</TableCell>
                        {isFullAgent() || isGatewayAccessibleListing(listing) ? (
                          <TableCell align="right">
                            <RbButton
                              onClick={(e) => handleClick(e, listing)}
                              className="rb-button--floorplans"
                            >
                              View Details
                              <i className="fas fa-external-link-alt" />
                            </RbButton>
                          </TableCell>
                        ) : null}
                        <TableCell align="left">
                          {showOrHideShareLinkIcon(listing)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : formattedPlans.filter(filterSelectedTab).map((listing) => (
                    <TableRow key={listing.apartment_id} onClick={() => handleRowClick(listing)}>
                      <TableCell align="right">
                        {listing.bookmarked ? (
                          <i
                            onClick={(e) =>
                              toggleBookmarks(e, [listing.apartment_id])
                            }
                            className="fas fa-bookmark floorplan__title--bookmark"
                          />
                        ) : null}
                      </TableCell>
                      <TableCell align="right">
                        <p>
                          {listing.bedrooms} Bed
                          {parseInt(listing.bedrooms) > 1 ? "s" : null}
                        </p>
                        <p>
                          {listing.bathrooms} Bath
                          {parseInt(listing.bathrooms) > 1 ? "s" : null}
                        </p>
                      </TableCell>
                      <TableCell align="right">
                        <p>
                          {listing.floor_plan_units_available || 1} Unit
                          {listing.floor_plan_units_available > 1 ? "s" : null}
                        </p>
                        <p>{getListingPriceLabel(listing)}</p>
                      </TableCell>
                      <TableCell align="right">
                        {getAvailableDateLabel(listing)}
                      </TableCell>
                      <TableCell align="right">
                        <RbButton
                          onClick={(e) => handleClick(e, listing)}
                          className="rb-button--floorplans"
                        >
                          View
                        </RbButton>
                      </TableCell>
                      {(listing?.use_apply_now && (listing?.status === 'Active' || listing?.status === 'Application Pending')) &&
                        <TableCell align="right">
                          <img
                            width="30"
                            src={applyNowLogo}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              openInviteApplicationModal(listing)
                            }}
                          />
                        </TableCell>
                      }
                      <TableCell align="right">
                        {showOrHideShareLinkIcon(listing)}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {shareListing && (
            <ShareDialog
              shareDialogOpen={shareDialogOpen}
              handleDialogClose={handleDialogClose}
              shareListing={shareListing}
            />
          )}
        </div>
        <Dialog
          open={openModal}
          onClose={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenModal(false);
              setNewClientModal(false)
              setApplicants([clientContactInfo])
          }}
          maxWidth="xs"
          fullWidth
          PaperProps={{
              style: {
                  padding: 0,
                  margin: 0,
                  position: 'relative'
              },
          }}
        >
            {
                newClientModal ? (
                    <NewClientModal
                        dialogClassess={dialogClassess}
                        closeModal={closeNewClientModal}
                        clientContactInfo={clientContactInfo}
                        selectClient={selectClient}
                        getClients={getClients}
                        newClientIndex={newClientIndex}
                    />
                ) : (
                    <ApplicationInviteModal
                        selectedRow={selectedRow}
                        closeModal={closeModal}
                        dialogClassess={dialogClassess}
                        clients={clients}
                        selectClient={selectClient}
                        selectedClient={selectedClient}
                        openNewClientModal={openNewClientModal}
                        applicants={applicants}
                        addNewAppicant={addNewAppicant}
                        removeApplicant={removeApplicant}
                        inviteApplicants={inviteApplicants}
                    />
                )
            }
        </Dialog>
      </>
    );
  }
)

interface IFormattedFloorplans extends IListing {
  bookmarked: boolean;
}

export interface IFloorPlanDetails {
  listings: IListing[];
  groupListingId: number;
  isMlsAgent?;
  pageNumber?: number;
  handleToggleUpgradeModal: (listingId?: any) => void
  isAddressDisplay?: boolean
}
