import React, { useRef } from 'react'
import applyNowLogo from "../../../../../assets/images/broker_portal/apply_now_logo.png";
import { DialogTitle, IconButton,
  DialogContent,
  DialogActions,
 } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClientDropDown from './client_dropdown';
import NewClientModal from './new_client_modal';

const ApplicationInviteModal = ({
  selectedRow,
  closeModal,
  dialogClassess,
  clients,
  selectClient,
  selectedClient,
  applicants,
  addNewAppicant,
  removeApplicant,
  openNewClientModal,
  inviteApplicants
}) => {
  const containerRef = useRef(null);
  return (
    <React.Fragment>
      <DialogTitle className={dialogClassess.dialogTitle}>
          <div style={{position: 'absolute', right: 5, cursor: 'pointer', zIndex: 50}}>
              <IconButton aria-label="close" onClick={(e)=>{ closeModal() }}>
                <CloseIcon fontSize="large"/>
              </IconButton>
          </div>
          <div className='dialog-header'>
            <img src={applyNowLogo} width={'80'}/>
            <div style={{flex: 2}}>
                <p className='dialog-title'>
                  Invite Applicants to Apply For:
                </p>
                <p className='address'>
                  {selectedRow.address}
                </p>
            </div>
        </div>
        <hr className='modal-hr'/>
      </DialogTitle>
      <DialogContent className={dialogClassess.dialogContent} ref={containerRef}>
          <div className='add-applicant-container'>
              <p>
                Applicants
              </p>
              <button onClick={addNewAppicant}>
                <AddIcon fontSize="large"/>
              </button>
              <button onClick={removeApplicant}>
                <RemoveIcon fontSize="large"/>
              </button>
          </div>
          {applicants.length > 0 && applicants.map((applicant,index) => {
            return (
                <div key={index} className="applicant-list" style={{marginTop: 15}}>
                  <div className='applicant-counter'>
                    Applicant {index + 1}
                  </div>
                  {applicant.email !== "" ? 
                  <>
                      <div style={{display: 'flex', gap: 10}}>
                        <span>Name:</span>
                        <span>
                          {applicant.name}
                        </span>
                      </div>
                       <div  style={{display: 'flex', gap: 10}}>
                        <span>Phone:</span>
                        <span>
                          {applicant.phone}
                        </span>
                     </div>
                      <div  style={{display: 'flex', gap: 10}}>
                        <span>Email:</span>
                        <span>
                          {applicant.email}
                        </span>
                    </div>
                  </>
                  : 
                    <ClientDropDown 
                      clients={clients}
                      selectClient={selectClient}
                      selectedClient={selectedClient}
                      openNewClientModal={openNewClientModal}
                      index={index}
                      containerRef={containerRef}
                      applicants={applicants}
                    />
                  }
              </div>
            )
          })}

      </DialogContent>
      <DialogActions className={dialogClassess.dialogActions}>
          <button
            disabled={!applicants.filter(applicant => applicant.id).length}
            className='invite-applicant-btn'
            onClick={inviteApplicants}
          >
            Invite Applicants
          </button>
          
        </DialogActions>
    </React.Fragment>
  )
}

export default ApplicationInviteModal
